
const elm = {
  burger  : '#js-burger',
  close   : '#js-close-menu',
  open    : 'open-menu',
  trigger : '#sm-menu .trigger',
  title   : '#sm-menu .nav-category',
  speed: 300
}



// nav のソースを sm 用に整形してコピーする
const element = $('#nav').clone()
element.find(elm.title).unwrap()
element.appendTo('#sm-menu')
$('#sm-menu').wrapInner('<div class="sm-nav">')



// burger click
$( elm.burger ).on( 'click', function(){
  $( 'body' ).addClass( elm.open );
  //$(opt.menuList).slideToggle( opt.slideDownSpeed );
})

// メニューを閉じる
$(document).on('click', elm.close, function() {
  $( 'body' ).removeClass( elm.open )
})

// 子メニューのアクション
$(document).on('click', elm.trigger, function() {
  const self = $(this)
  $(elm.trigger).not(self).next().slideUp( elm.speed / 2 )
  self.next().slideToggle( elm.speed );
});

// 孫メニューのアクション
$(document).on('click', elm.title, function() {
  const self = $(this)
  $(elm.title).not(self).next().slideUp( elm.speed / 2 )
  self.next().slideToggle( elm.speed );
});

/*
// PCメニュー ホバー時に class を付与する
$('#hoverNav').children('li')
  .mouseenter( function(){
    $(this).find('.global-nav-inner-container').addClass('is-active')
    //$(this).next('.global-nav-inner-container').stop().fadeIn(200)
  }).mouseleave( function(){
    $(this).next('.global-nav-inner-container').removeClass('is-active')
    //$(this).next('.global-nav-inner-container').stop().fadeOut(200)
  })
*/
